@import "styles/common";

.root {
  @include flex--column();
  hr {
    border-top: 1px solid color("grey", "lighter");
    outline: none;
    line-height: 1;
  }
  h3 {
    margin: 0;
  }
  h5 {
    font-weight: $fontMedium;
    color: color(text, primary);
  }
  //max-width: 860px;
  min-height: 600px;
  //min-width: 20%;
}

.header {
  @include flex--column(center);
  @include desktop {
    @include flex--row(flex-end, space-between);
  }

  margin-right: 42px;
  .heading {
    @include flex--column(center);
    @include desktop {
      @include flex--row(center);
    }

    & > span {
      margin-left: 12px;
      & > * {
        margin-right: 4px;
      }
    }
  }
  h1 {
    margin: 0;
  }
}
.headerYear {
  color: color(text, light);
  font-size: rem(24px);
  font-weight: $fontNormal;
}

.row {
  @include flex--row(flex-start, flex-start, wrap);
  & > div {
    margin-bottom: 4px;
  }
  @include desktop {
    //flex-wrap: nowrap;
  }
  & > div {
    margin-right: 4px;
  }
  &.credits {
    //margin-bottom: 8px;
    svg {
      color: color(text, light);
      margin-left: 4px;
      font-size: 18px;
    }
  }
}
.wrapRow {
  @include flex--row(flex-start, space-between, wrap);
  @include desktop {
    & > div {
      width: 49.5%;
    }
  }
}
.noShrink {
  flex-shrink: 0;
}

.emphasis {
  color: color(text, primary);
}

.textBox {
  //@include flex--row(flex-start, flex-start, wrap);
  @include desktop {
    flex-wrap: nowrap;
  }
  //background-color: color(grey, lightest);
  margin-top: -10px;
  margin-left: 20px;
  word-wrap: break-word;
  font-size: 15px;
}
.shadedRow {
  background-color: color(grey, lightest);
}
.dateError {
  background-color: color(danger, lightest);
}

.actions {
  @include flex(center, flex-end);
}

.waivedChip,
.waived {
  @include stripes();
}
.tabRow {
  @include flex--row(center);
}

.waived {
  color: darkblue;
}

.box {
  margin-bottom: -10px;
}

.iconFileSize {
  background-color: color(grey, light);
  font-size: 100px;
  border-radius: 5px;
}

.column {
  @include flex--column(flex-start);
}

.column:hover {
  background-color: color(success, light);
}

.widthButton {
  min-height: 35px;
}
