@import "styles/common";
$radius: 8px;
.label {
  //margin-bottom: 2pt;
  display: block;
  text-align: left;
  color: color("grey", "dark");
  font-size: 0.9em;
  font-weight: $fontBold;

  //padding-left: 14px;
  span {
    color: color(text);
    font-weight: normal;
    font-style: italic;
  }
}

//standard input styles
.input {
  &,
  &[type="email"],
  &[type="number"],
  &[type="search"],
  &[type="text"],
  &[type="tel"],
  &[type="url"],
  &[type="password"],
  &[type="date"],
  &.area {
    appearance: none;
    display: block;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    color: color(text, dark);
    font-family: $fontBody;
    font-weight: $fontNormal;
    font-size: 1.05em;

    border: 1px solid #eee;
    background-color: rgba(color("grey", "lightest"), 0.6);
    border-radius: $radius;
    padding: 12px 18px;

    transition: 0.7s;

    &::placeholder {
      color: lighten(color(text), 30%);
      font-size: 0.9em;
    }
    &:focus {
      outline: 0;
      border-color: transparentize(color(primary), 0.5);
      background-color: $background;
    }

    &:disabled {
      cursor: not-allowed;
      color: color("text", light);
      //background-color: transparentize(color("grey", "lighter"), 0.3);
      //background-color: transparentize(color("grey", "lighter"), 0.3);
      background: none;
    }
  }
}

// root states
.error {
  .label {
    color: color("text", "danger");
    font-weight: bold;
  }
  .input {
    border-color: transparentize(color(danger), 0.5);
  }
}

.disabled {
  .label {
    font-weight: $fontMedium;
    color: color(grey, light);
  }
}
