@import "styles/common";

.root {
  padding: 0 12px;

  .title {
    color: color(primary, light);
    font-size: rem(18px);
    font-weight: $fontBold;
  }
}

.questions {
  @include flex--desktop(flex-start, space-between) {
    flex-wrap: wrap;
    width: 100%;
    .fullWidth {
      width: 100%;
    }
    .halfWidth {
      width: 49.5%;
    }
  }
}
