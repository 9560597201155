@import "../config/typography";
@import "../utils/color";

p {
  margin-bottom: 1.3em;
}

// Loop through each heading size
@each $name, $size in $headingFontSizes {
  #{$name} {
    font: $fontNormal $size $fontHeadings;
    line-height: 1.35;
    margin: 1.414em 0 0.5em;
  }
}

h1 {
  font-weight: $fontMedium;
  margin: 0 0 0.5em;
  color: color(primary);
  letter-spacing: 0.02em;
}

h2 {
  color: color(secondary);
}

h3,
h4 {
  color: color(text, dark);
}

h5,
h6 {
  line-height: 1.4;
  color: color(text);
  font-weight: $fontNormal;
}

small,
h6 {
  margin: 0.25em 0;
}

img,
svg {
  max-width: 100%;
}

pre,
code {
  font-family: monospace, monospace;
  font-weight: $fontNormal;
  color: color(text, danger);
  background: color(grey, lightest);
  border: 1px solid color(primary, lightest);
  border-radius: 4px;
  font-size: 0.9em;
}
pre {
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  padding: 8pt 16pt;
  width: 100%;
  //max-width: 85%; // Remove later
  overflow: auto;
  border: 1px solid #eee;
  code {
    font-size: 1em;
    background: none;
    border: none;
    padding: 0;
  }
}

code {
  padding: 2px 6px;
}

blockquote {
  background: #f9f9f9;
  border-left: 6px solid color(primary, lightest);
  margin: 1.5em 10px;
  padding: 0.5em 16px;
  border-radius: 2px;
  //quotes: "\201C""\201D""\2018""\2019";

  &:before {
    color: #ccc;
    //content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  p {
    display: inline;
  }
}
