@use "sass:math";
@import "styles/common";

$radius: 22px;

.chip {
  border-radius: $radius;
  padding: math.div($radius, 4) math.div($radius, 1.4);
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  &.disabled {
    cursor: default;
  }
  &.clickable {
    cursor: pointer;
  }
  &.condensed {
    padding-top: rem(math.div($radius, 6));
    padding-bottom: rem(math.div($radius, 6));
    //padding-left: 0;
    font-size: rem(12px);
    font-weight: $fontBold;
    line-height: 1.7;
    &.chipIcon {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      .content .icon {
        align-self: flex-start;
        margin-right: 4px !important;
        & > svg {
          font-size: rem(18px);
        }
        &.iconEmpty {
          margin-left: 4px;
        }
      }
    }
  }
}

.chipIcon {
  padding-left: math.div($radius, 3);
  .content {
    display: flex;
    align-items: center;
    .icon {
      margin-right: rem(10px);
    }
  }
}

.icon {
  flex-shrink: 0;
  border-radius: 50%;
  height: $radius + 6px;
  width: $radius + 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $fontBold;
  & > svg {
    font-size: $radius;
    //width: $radius;
  }
  &,
  &.iconFilled {
    color: color("grey", "dark");
    background-color: darken(color("grey", "light"), 5%);
  }
  &.iconOutline {
    background: none;
    border: 2px solid transparentize(color("grey", "base"), 0.3);
    color: color("grey", "base");
  }
  &.iconEmpty {
    margin-right: rem(4px);
    background: none;
    color: color("grey", "base");
  }

  &.condensed {
    height: $radius + 2px;
    width: $radius + 2px;
    & > svg {
      font-size: rem(16px);
    }
  }
}

.default {
  color: color("text", "dark");
  background: color("grey", "lighter");
  &.darkOutline,
  &.variantOutline {
    border: 2px solid color("grey", "lighter");
  }
  &.variantEmpty,
  &.variantOutline {
    background: none;
  }
  &.variantLightFilled {
    background: rgba(color("grey", "lightest"), 0.9);
  }
}

@mixin chipVariant($key, $textColor: #fff) {
  color: $textColor;
  background: color($key, "dark");
  &.icon {
    color: color($key, "light");
  }
  &.variantFilled {
  }
  &.variantEmpty,
  &.variantLightFilled,
  &.variantOutline {
    color: color($key, "dark");
    background: none;
    .icon {
      color: color($key, "dark");
    }
  }
  &.darkOutline,
  &.variantOutline {
    border: 2px solid color($key);
  }
  &.lightOutline {
    border: 2px solid color($key, "lighter");
  }
  &.variantLightFilled {
    color: color($key, "darker");
    background: rgba(color($key, "lightest"), 0.8);
  }
  &.icon {
    color: color($key, "light");
    &.iconFilled {
      color: color($key, "dark");
      background-color: color($key, "lighter");
    }
    &.iconOutline {
      background: none;
      border: 2px solid transparentize(color($key, "lighter"), 0.3);
      color: color($key, "lighter");
    }
    &.iconEmpty {
      color: color($key, "lighter");
    }
    &.iconLightFilled {
      color: color($key, "darker");
      background: rgba(color($key, "lightest"), 0.8);
    }
  }
  &.clickable {
    transition: border 200ms ease-in-out;
    &.variantFilled {
      border: 1px solid color($key, "dark");
      &:focus {
        border: 1px solid color($key, "darkest");
        background: color($key);
      }
    }
    &.variantLightFilled {
      border: 1px solid rgba(color($key, "lightest"), 0.8);
      &:focus {
        background: rgba(color($key, "lightest"), 0.98);
        border-color: rgba(color($key, "light"), 0.4);
      }
    }
    &.variantOutline {
      &:focus {
        background: rgba(color($key, "lighter"), 0.4);
        border-color: color($key, "darker");
      }
    }
  }
}

.primary {
  @include chipVariant("primary");
}
.secondary {
  @include chipVariant("secondary");
}
.success {
  @include chipVariant("success");
}
.info {
  @include chipVariant("info");
}
.warning {
  @include chipVariant("warning");
}
.danger {
  @include chipVariant("danger");
}
.grey {
  @include chipVariant("grey");
}

.skeletonChip {
  position: relative;
  color: color(grey);
  .content {
    position: relative;
  }
  .skeleton {
    z-index: -1;
    //height: 100%;
    border-radius: $radius;
  }
}
