@import "../utils";
@import "../config/typography";

// Default buttons and links
a {
  font: $fontNormal $fontSize $fontBody;
  color: color(secondary, dark);
}

button {
  font: $fontNormal $fontSize $fontBody;
}

.link,
button.link {
  color: color(secondary);
  text-decoration: none;
  transition: 0.7s;
  cursor: pointer;
  @include desktop {
    &:hover,
    &:focus {
      text-decoration: underline;
      color: color(secondary, dark);
    }
  }
}
li {
  margin-bottom: 4px;
  &::marker {
    font-size: 0.8em;
    color: color(primary, light);
  }
}

ol {
  li {
    text-indent: rem(6px);
    &::marker {
      font-size: 0.8em;
    }
  }
}
