@import "styles/common";

.root {
}

.bgGrey {
  margin-top: 16px;
  background-color: rgba(color(grey, lightest), 0.5);
  border-top: 1px solid color("grey", "lighter");
}

.tabLock {
  font-size: rem(14px);
  display: inline-block;
  vertical-align: bottom;
  //margin-top: 4px;
  margin: 0 4px 2px 0;
  opacity: 0.6;
  //align-self: flex-end;
}
.spacer {
  flex-grow: 1;
}
.hr {
  margin: 32px 0 12px 0;
  //border-color: color("grey", "lighter");
  border-top: 1px solid color("grey", "lighter");
}

.groupHeader {
  @include flex--row(flex-end, space-between);
}
.activityList {
  list-style-type: none;
  padding-inline-start: 0;
  @include desktop {
    padding-inline-start: inherit;
  }
}
.row {
  @include flex--row(flex-end, flex-end, wrap);
  @include desktop {
    @include flex--row(center, flex-start);
    & > div {
      margin-left: 8px;
    }
  }
  h3 {
    flex-shrink: 0;
  }
  h2 {
    margin-top: 12px;
  }
}
.gantt {
  margin-top: 24px;
  overflow-x: auto !important;
  @include subtle_horizontal_scrollbar;
  & > div {
    min-width: 900px;
  }
}

.counter {
  padding: 0;
  margin-left: 4px;
  @include flex--row(center, space-between);
  .label {
    color: color(primary, light);
    font-weight: $fontBold;
    padding: 6px 8px 6px 16px;
    font-size: 0.9em;
    //margin-right: 12px;
  }
  .count {
    color: color(primary);
    background-color: #fff;
    font-weight: $fontBold;
    height: 100%;
    padding: 6px 16px 6px 12px;
    border: 2px solid color(primary, lightest);
    border-radius: 0 22px 22px 0;
  }
}
.reqLabel {
  font-weight: $fontBold;
  color: color(text, dark);
  font-size: rem(16px);
  margin: 24px 0 12px 0;
  span {
    padding-left: 4px;
    font-weight: $fontNormal;
    font-style: italic;
  }
}

.waived {
  @include stripes();
}
