@import "styles/common";

.root {
  width: 94%;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: $mediumShadow;
  @include flex--column(stretch, space-between);
  @include desktop {
    @include flex--row(stretch, space-between);
  }

  margin: 8px;
  color: color(text);
  .editable {
    transition: 400ms ease-in-out;
    transition-property: background-color, box-shadow;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
      //transform: scale(1.0001);
      box-shadow: $heavyShadow;
    }
  }
  &.planned {
    background-color: rgba(color(grey, lightest), 0.7);
    box-shadow: $lightShadow;
    color: color(text, dark);
  }
  &.waived {
    background-color: rgba(color(info, lightest), 0.3);
    @include stripes(color(info, lighter), 10px);
  }
  h4 {
    margin: 8px 0 -4px 0;
    color: color(primary);
    font-weight: $fontMedium;
  }
}
.column {
  @include flex--column(flex-start, space-between);
  text-align: left;
  &.endColumn {
    text-align: right;
    align-items: flex-end;
  }
}
.row {
  @include flex--row(center);
  & > div {
    margin-right: 4px;
  }
  &.credits {
    //margin-bottom: 8px;
    svg {
      color: color(text, light);
      margin-left: 4px;
    }
  }
  &.credits,
  &.attachment {
    svg {
      font-size: 18px;
    }
  }
}
.date {
  margin-top: 6px;
  font-style: italic;
}

h4 {
  margin-top: 15px;
}

.input {
  border-style: none;
  -webkit-border-radius: 10px;
  margin-bottom: 10px;
  width: 90%;
  min-height: 4.4rem;
  background-color: #e5e5e5;
  //  display: flex;
  //align-items: center;
  //justify-content: center;
}

.grid {
  display: grid;
  grid-template-columns: 20% 50% 30%;
  //grid-template-rows: 7rem 7rem;
  grid-template-areas:
    "s1 s2 s3"
    "s1 s2 s3";
}
.s1 {
  margin-left: 5px;
  grid-area: s1;
}
.s2 {
  grid-area: s2;
  //background-color: red;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex: 1 100%;
}

.s3 {
  grid-area: s3;
  //background-color: green;
}

.sameLine {
  float: left;
}

.buttonStyle {
  justify-content: flex-end;
  display: flex;
  //margin-bottom: -50px;
}

.s3Text {
  justify-content: flex-end;
  display: flex;
  margin-right: 10px;
}

.buttonColour {
  //color: green;
}
