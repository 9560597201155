@import "styles/common";
@import "../common/Input.module";

.inputWrapper {
  position: relative;
}
.expand {
  position: absolute;
  right: 16px;
  top: 8px;
  color: color("grey", "light");
  font-size: rem(28px);
  pointer-events: none;
  cursor: pointer;
  @media print {
    display: none;
  }
}
:global(.react-datepicker-wrapper) {
  .chip {
    border: 1px solid rgba(color("grey", "lightest"), 0.8);
    background-color: rgba(color("grey", "lightest"), 0.6);
    &:global(.react-datepicker-ignore-onclickoutside) {
      border-color: transparentize(color(primary), 0.5);
      outline: 0;
      background-color: $background;
    }
  }
}
.chip {
  height: 38px;
  cursor: pointer;
  transition: 0.7s;
  .placeholder {
    padding: 4px 10px 4px 2px;
    color: lighten(color(text), 30%);
    line-height: 1.9;
    font-weight: $fontNormal;
  }
  label {
    flex-shrink: 0;
    pointer-events: none;
    padding: 6px 6px 4px 12px;
    font-size: rem(11px);
    font-weight: $fontBold;
    color: color("grey");
    margin-left: -10px;
    width: fit-content;
  }
  .expand {
    position: relative;
    top: 0;
    right: 0;
    margin-right: -8px;
    //right: 16px;
    //top: 8px;
    color: color("grey", "light");
    font-size: rem(28px);
    pointer-events: none;
    cursor: pointer;
    @media print {
      display: none;
    }
  }
}

.pickerPopper {
  z-index: 9999;

  :global(.react-datepicker) {
    font: $fontNormal rem(13px) $fontBody;
    border-radius: 6px;
    border-color: color(primary, lighter);
    //padding: rem(4px);
    :global(.react-datepicker__header) {
      border-radius: 6px 6px 0 0;
      padding: rem(13px);
      background-color: color(grey, lightest);
      border-color: color(grey, lighter);
      :global(.react-datepicker__current-month) {
        font-size: rem(15px);
        font-weight: $fontMedium;
      }
    }

    :global(.react-datepicker__day-name),
    :global(.react-datepicker__day) {
      width: rem(28px);
      line-height: rem(28px);
      &:global(.react-datepicker__day--outside-month) {
        color: color(text, light);
      }
    }
    :global(.react-datepicker__day--selected),
    :global(.react-datepicker__day--keyboard-selected),
    :global(.react-datepicker__day--in-selecting-range),
    :global(.react-datepicker__day--in-range),
    :global(.react-datepicker__month-text--selected),
    :global(.react-datepicker__month-text--in-selecting-range),
    :global(.react-datepicker__month-text--in-range),
    :global(.react-datepicker__quarter-text--selected),
    :global(.react-datepicker__quarter-text--in-selecting-range),
    :global(.react-datepicker__quarter-text--in-range),
    :global(.react-datepicker__year-text--selected),
    :global(.react-datepicker__year-text--in-selecting-range),
    :global(.react-datepicker__year-text--in-range),
    :global(.react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item--selected) {
      background-color: color(primary, light);
      &:hover {
        background-color: color(primary, dark);
      }
    }
    :global(.react-datepicker__triangle) {
      border-color: color(primary, lighter);
      margin-left: -20px !important;
    }
    :global(.react-datepicker__time-list) {
      @include subtle_scrollbar(none);
      width: 99% !important;
    }
  }
}
.rangeWrapper {
  max-width: fit-content;
  input {
    min-width: 268px;
  }
  margin-bottom: -19px;
}

//TODO: figure out styling for remove date icon of react date picker
.react-datepicker__close-icon::after {
  background-color: red !important;
}
