@import "../common/input";

.trigger {
  width: 100%;
  max-width: 100%;

  .selected {
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    display: inline-block;
  }
}

.trigger,
.multiwrap {
  position: relative;
  @include flex--row {
    flex-wrap: wrap;
  }
  padding: 4px 42px 4px 12px;
  min-height: 42px;
  overflow-x: hidden;
  &:disabled {
    background: none;
    cursor: not-allowed;
  }

  .expandIcon {
    position: absolute;
    right: 0;
    top: 0;
    color: color(grey, light);
    opacity: 1;
  }
  .placeholder {
    padding: 4px 10px 4px 2px;
    color: #9f9f9f;
    font-size: 0.9em;
    line-height: 1.9;
  }
}
.trigger,
.bg {
  cursor: pointer;
  border-radius: $radius;
  border: 1px solid #eee;
  background-color: rgba(color("grey", "lightest"), 0.6);
  transition: 0.7s;
  &[aria-expanded="true"],
  &:focus {
    outline: 0;
    z-index: 1;
    border: 1px solid transparentize(color(primary), 0.5);
    background-color: $background;
    .expandIcon,
    & ~ .expandIcon {
      color: color(primary);
    }
  }
}

.multiwrap {
  appearance: none;

  .bg {
    //@include absolute-cover;
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    display: block;
    //margin: -1px;
  }
  input,
  .expandIcon,
  .selected,
  .active {
    z-index: 2;
  }
  .selected {
    border-radius: 20px;
    margin: 2px 2px 2px 0;
    overflow-x: auto;
    button {
      margin: 0;
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      display: inline-block;
    }
  }
}
