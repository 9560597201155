@import "styles/common";

.root {
  //margin-top: 10px;
}
.tabListWrapper {
  position: relative;
  width: 100%;
  max-width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
  .tabShadow {
    pointer-events: none;
    @include absolute-cover();
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      border-bottom: 1px solid color("grey", "lighter");
      bottom: 15px;
      //box-shadow: 0 6px 4px -4px color("grey", "lighter");
      //bottom: 17px;
      height: 24px;
      left: -2px;
      right: -2px;
      z-index: 0;
    }
  }
}
.tabList {
  position: relative;
  display: flex;
  padding: 4px 12px 20px 12px;
  min-width: 100%;
  width: fit-content;
  .tabContent {
    position: relative;
    min-width: max-content;
    flex-grow: 1;
    @include flex--row();
    @include huge {
      max-width: $containerMax;
      margin-left: auto;
      margin-right: auto;
      min-width: initial;
    }
  }
  .tab {
    font-size: rem(14px);
    position: relative;
    min-width: 100px;
  }
}

$decoratorRadius: 6px;
.decorator {
  pointer-events: none;
  position: absolute;
  top: -4px;
  transition: all 300ms ease;
  bottom: -4px;
  z-index: 1;
  //border-radius: 20px 20px 0px 0px;
  //background-color: transparentize(color('grey', 'lightest'),0.6);
  //
  //&:before{
  //  background: color("secondary",'lighter');
  //  position: absolute;
  //  content: "";
  //  left: 0;
  //  right: 0;
  //  bottom: -1.5px;
  //  height: 2px;
  //  border-radius: 6px;
  //}
  @include psuedo--after {
    bottom: $decoratorRadius * -1;
  }
}

.tabListWrapper {
  padding-top: 6px;
  &.dotDecorator {
    .decorator {
      &:after {
        bottom: $decoratorRadius * -1;
        left: calc(50% - #{$decoratorRadius});
        width: $decoratorRadius * 2;
        height: $decoratorRadius * 2;
        border-radius: $decoratorRadius;
        background: color("secondary");
        box-shadow: 0 2px 3px 0 color("grey", "lighter");
      }
    }
  }
  &.lineDecorator {
    .decorator::after {
      bottom: -2px;
      //left: calc(50% - #{$decoratorRadius});
      left: 0;
      right: 0;
      //width: $decoratorRadius * 2;
      height: 4px;
      border-radius: $decoratorRadius;
      background: color("secondary", "light");
      box-shadow: 0 2px 3px 0 color("grey", "lighter");
    }
  }
  &.tabBackground {
    .tabList {
      padding-bottom: 0;
    }
    .tab {
      padding: rem(6px) rem(24px);
      @include desktop {
        padding: rem(10px) rem(32px) rem(6px) rem(32px);
      }
    }
    .decorator {
      border-radius: 8px 8px 0 0;
      margin-bottom: -1px;
      background-color: #fff;
      border: 1px solid color("grey", "lighter");
      border-bottom: none;

      //padding-bottom: 2px;
      //&:after {
      //  bottom: ($decoratorRadius * -1)+2;
      //}

      //box-shadow: 0px -1px 4px 0px color("grey", "lighter");
      //@include psuedo--before() {
      //  background-color: #fff;
      //  width: 100%;
      //  bottom: -6px;
      //  height: 8px;
      //}
    }
    &.lineDecorator {
      .decorator::after {
        //left: 4px;
        //right: 4px;
        border-radius: 0;
        bottom: 0;
        box-shadow: none;
      }
    }
    .tabShadow {
      &:after {
        bottom: 0;
      }
    }
  }
}

.tab {
  z-index: 2;
  display: inline-block;
  padding: rem(6px) rem(18px);
  margin: 0;
  border: none;
  border-bottom: 1px solid transparent;
  background: none;
  color: color("text");
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: $fontMedium;
  transition: color, opacity 200ms ease-in-out;
  letter-spacing: 0.016em;
  opacity: 0.6;
  &.active {
    font-weight: $fontBold;
    color: darken(color("secondary"), 4%);
    opacity: 1;
  }
  &:hover,
  &:focus {
    color: color("primary", "dark");
    opacity: 1;
    &.active {
      background: none;
      color: color("secondary", "dark");
    }
  }
}
