@import "styles/common";
.root {
  padding: 0 1px 0 1px;
  margin-bottom: 16px;
}

.menu {
  //padding-bottom: 40px;
  //position: relative;
}
.footer {
  //position: fixed;
  padding: rem(12px);
  width: 100%;
  //height: 40px;
  background-color: color(grey, lightest);
}
