@import "styles/common";

.root {
  @include flex--column();
  min-height: 600px;
  hr {
    line-height: 1;
    width: 99%;
    margin: 8px auto;
    box-sizing: content-box; /* 1 */
    height: 0; /* 1 */
    overflow: visible; /* 2 */
    border: none;
    border-bottom: 2px solid color(grey, lightest);
  }
  h6 {
    margin: 12px 0 4px 0;
  }
}

.row {
  @include flex--row(flex-start, flex-start, wrap);
  @include desktop {
    flex-wrap: nowrap;
  }
  & > div {
    margin-right: 4px;
  }
  &.credits {
    //margin-bottom: 8px;
    svg {
      color: color(text, light);
      margin-left: 4px;
      font-size: 18px;
    }
  }
}
.shadedRow {
  background-color: color(grey, lightest);
}
.dateError {
  background-color: color(danger, lightest);
}

.wrapRow {
  @include flex--row(flex-start, space-between, wrap);
  @include desktop {
    & > div {
      width: 49.5%;
    }
  }
}

.actions {
  @include flex(center, flex-end);
  align-self: flex-end;
}

.waived {
  color: darkblue;
  @include stripes();
}

.stepProgress {
  width: 80% !important;
  margin: 0 auto;
}

.iconFileSize {
  background-color: color(grey, light);
  font-size: 100px;
  border-radius: 5px;
}
.column {
  @include flex--column(flex-start);
}

.column:hover {
  background-color: color(danger, light);
}

.deletedAccordian {
  width: 88%;
  max-width: 1400px;
  margin: 24px auto 12px auto;
  position: relative;
  border-bottom: 1px solid color(success, lighter);
  h4 {
    color: color(text, success);
    font-weight: $fontBold;
  }
}

.requiredChip {
  align-self: center;
}
