@import "styles/common";

.root {
  margin: 2px 2px 2px 0;
  position: relative;
  transition: opacity 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    .iconCancel {
      color: color("grey", "darkest");
    }
  }
  &.cancel {
    padding-right: 28px;
  }
}

.iconCancel {
  color: color("grey");
  font-size: 18px;
  position: absolute;
  right: 6px;
  //top: calc(50% - 9px);
}
