@import "styles/common";

.option {
  list-style-type: none;
  padding: 6px 12px;
  transition: background-color 100ms ease-in-out;
  &.highlighted {
    background-color: color("grey", "lightest");
    &.active {
      background-color: rgba(color("primary", "lighter"), 0.4);
    }
  }
  &.active {
    font-weight: $fontBold;
    color: color("text", "primary");
    background-color: rgba(color("primary", "lightest"), 0.3);
    & > div {
      border: 1px solid color("primary", "lighter");
    }
  }
  &,
  & > button {
    cursor: pointer;
  }
}
$themeVariants: (primary, secondary, success, info, warning, danger, grey);

.dot {
  display: inline-block;
  //margin-left: -6px;
  margin-right: 8px;
  width: 8px;
  height: 8px;
  border-radius: 16px;
  background-color: color(primary);
  @for $i from 1 through length($themeVariants) {
    $c: nth($themeVariants, $i);
    &.#{$c} {
      background-color: color($c);
    }
  }
}
