@import "styles/common";

.root {
}

.row {
  width: 100%;
  @include desktop {
    @include flex--row(center, flex-start);
  }
  padding-bottom: 16px;
}

.endInput {
  @include desktop {
    margin-left: 12px;
  }
  input {
    background: none !important;
  }
}

.yearRow {
  @include flex--row(center);
  padding-bottom: 8px;
  .icon {
    opacity: 0.5;
    margin-right: 6px;
    font-size: rem(18px);
    width: rem(18px);
  }
}
