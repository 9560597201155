@use "styles/common" as theme;

.editor {
  :global(.ProseMirror) {
    &[contenteditable="true"] {
      border: 1px solid #eee;
      background-color: rgba(theme.color("grey", "lightest"), 0.6);
      padding: 12px 18px;
      min-height: 100px;

      &:global(.ProseMirror-focused) {
        outline: 0;
        border-color: transparentize(theme.color(primary), 0.5);
        background-color: theme.$background;
      }
    }
    border-radius: 0 8px 8px 8px;
    transition: border-color 0.7s, background-color 0.7s;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
      margin-bottom: 0.2em;
    }
    p {
      margin: 0;
    }
  }
}
