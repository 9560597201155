@import "styles/common";

.wrapRow {
  @include flex--row(flex-start, space-between, wrap);
  @include desktop {
    & > div {
      width: 49.5%;
    }
  }
}
