@mixin invisible {
  opacity: 0;
  transition: 0.7s;
}

@mixin left-align {
  text-align: left;
}

@mixin left-align {
  text-align: left;
}

@mixin right-align {
  text-align: right;
}

@mixin stripes($color: #fff, $size: 5px, $alpha: 0.2) {
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent $size,
    rgba($color, $alpha) $size,
    rgba($color, $alpha) $size * 2.4
  );
}
