@import "styles/common";

.root {
  h2 {
    color: color("secondary", "dark");
  }
  hr {
    border-top: 1px solid color("grey", "lighter");
  }
}
