@import "styles/common";

.root {
  //color: color(text, light) !important;
  //box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
  //border-radius: 24px;
  height: 36px;
  width: 36px;
  padding: 0 !important;
  //background-color: #fff !important;
  //border: none !important;
}
.icon {
  @include flex(center, center);
  svg {
    font-size: rem(22px);
  }
}
