@import "styles/common";

.root {
  display: grid;
  border: 0;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  //box-shadow: $lightShadow;
  //box-shadow: 0 75px 125px -57px #7e8f94;
  &.noLabelColumn {
    .row {
      grid-template-columns: 1fr;
    }
    .header,
    .verticalLines {
      grid-template-columns: repeat(12, 1fr);
    }
  }
}

.row {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns: 150px 1fr;
  &:nth-child(odd) {
    background-color: color(grey, lightest);
    .rowLabel {
      background-color: color(grey, lightest);
    }
  }
  &:nth-child(3) {
    .bars {
      border-top: 0;
    }
    .rowLabel {
      border-top: 0;
    }
  }
  .empty {
    background-color: color(danger) !important;
    z-index: 1;
    .rowLabel {
      border-width: 1px 1px 0 0;
    }
  }
}

.rowLabel {
  border-width: 1px 0 0 0;
  border-color: rgba(0, 0, 0, 0.1);
  border-style: solid;
  padding: 15px 6px;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  line-height: 1.3;
}

.header {
  color: color(text, light);
  //background-color: #0a3444 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 150px repeat(12, 1fr);
  .rowLabel {
    border-top: 0 !important;
    //background-color: #0a3444 !important;
  }
  span {
    text-align: center;
    font-size: 13px;
    align-self: center;
    font-weight: bold;
    padding: 20px 0;
  }
}

.bars {
  list-style: none;
  display: grid;
  padding: 9px 0;
  margin: 0;
  grid-template-columns: repeat(24, 1fr);
  grid-gap: 8px 0;
  border-top: 1px solid rgba(221, 221, 221, 0.8);
}

$themeVariants: (primary, secondary, success, info, warning, danger, grey);

.bar {
  font-weight: $fontNormal;
  text-align: left;
  font-size: 14px;
  height: 32px;

  color: #fff;
  //overflow: hidden;
  position: relative;
  z-index: 4;
  cursor: pointer;
  border-radius: 20px;
  padding: 5px 12px;
  @include flex--row(center, flex-start);

  &.stripes {
    @include stripes();
  }
  &.event {
    height: 32px;
    width: 32px;
    &:hover {
      transform: scale(1.08);
    }
    .icon {
      position: absolute;
      //margin: 2px;
      left: 6px;
      top: 6px;
      margin: 0;
      > svg {
        font-size: 20px;
      }
    }
    .label {
      @include hidden;
    }
  }
  &:not(.event) {
    &.flatStart {
      //border-radius-topleft: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
    &.flatEnd {
      //border-radius-topleft: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .icon {
    > svg {
      font-size: 22px;
      opacity: 0.7;
    }
    flex-shrink: 0;
    margin-right: 4px;
    height: 22px;
  }
  .label {
    flex-grow: 1;
    padding-left: 2px;
    @include truncate();
  }

  &:before,
  &:after {
    content: "";
    height: 100%;
    top: 0;
    z-index: 4;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }

  @for $i from 1 through length($themeVariants) {
    $c: nth($themeVariants, $i);
    //theme variant
    &.#{$c} {
      @include text-outline(color($c, darker), 2px);
      background-color: color($c);
      &.lightFilled-fill {
        color: color($c, darker);
        text-shadow: none;
        //@include text-outline(color($c, lightest), 1px);
        background-color: color($c, lighter);
        .icon {
        }
      }
      &.empty-fill,
      &.outline-fill {
        .icon {
          color: color($c, dark);
          > svg {
            opacity: 1;
          }
        }
        &.stripes {
          @include stripes(color($c));
          //&.stripe-#{$c}{
          //  @include stripes(color($c));
          //}
        }
      }
      &.empty-fill {
        background: none;
      }
      &.outline-fill {
        background-color: color($c, lighter, 0.3);
        outline: 2px solid color($c);
      }
    }

    //outline variant
    &.outline-#{$c} {
      &,
      .outline-fill {
        outline: 5px double color($c) !important;
      }
    }
    &.stripes.stripe-#{$c} {
      @include stripes(color($c), 5px, 0.8);
    }
  }
}

.verticalLines {
  @include absolute-cover;
  z-index: 2;
  background-color: transparent;
  display: grid;
  grid-template-columns: 150px repeat(12, 1fr);
  pointer-events: none;
  span {
    display: block;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    &.marker {
      background-color: rgba(10, 52, 68, 0.13);
      z-index: 3;
    }
    &:nth-child(1) {
      //border-right: 1px solid rgba(0, 0, 0, 0.5);
      //border-right: 1px solid rgba(color(grey), 0.8);
      border-right: 1px solid color("primary", "light");
    }
  }
  &:after {
    grid-row: 1;
    grid-column: 0;
    background-color: #1688b345;
    z-index: 2;
    height: 100%;
  }
}

@mixin circle-arrange($item-count, $circle-size, $item-size) {
  > .dot {
    display: block;
    position: absolute;
    //top: 50%;
    //left: 50%;
    //width: $item-size;
    //height: $item-size;
    //margin: -($item-size / 2);

    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg)
          translate($circle-size / 2)
          rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}

.dotWrap {
  position: absolute;
  right: 13px;
  top: 13px;
  @include flex--column();
  $circle-size: 6px;
  .dot {
    position: absolute;
    height: $circle-size;
    width: $circle-size;
    border-radius: 50%;
    background-color: color(grey);
    transform-origin: bottom center;
  }
}
.tooltip {
  @include flex--row(center);
  position: relative;
}

.badgeWrap {
  position: absolute;
  //right: -12px;
  //top: -2px;
  right: -24px;
  top: -10px;
}

.badge {
  width: 18px;
  height: 18px;
  padding: 0;
  margin-bottom: 4px;
  @include flex(center, center);
  border-radius: 50%;
  svg {
    font-size: 12px;
  }
  @for $i from 1 through length($themeVariants) {
    $c: nth($themeVariants, $i);
    &.badge-#{$c} {
      background-color: color($c, lighter) !important;
      svg path {
        fill: color($c, darker) !important;
      }
    }
  }
}

.dot {
  @for $i from 1 through length($themeVariants) {
    $c: nth($themeVariants, $i);
    &.badge-#{$c} {
      //background-color: color($c, dark);
      background-color: color($c);
    }
  }
}
