@import "styles/common";

.address {
  font-style: normal;
}
.bg.address {
  margin-bottom: 6px;
  width: 100%;
  border-radius: 8px;
  padding: 12px 18px 12px 32px;
  font-weight: $fontBold;
  position: relative;
  background: transparentize(color("success", "lightest"), 0.6);
  color: color("success", "darkest");

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px 0 0 8px;
    background: color("success");
    width: 10px;
  }
}

.bg.address.customAddress {
  background: transparentize(color("warning", "lightest"), 0.6);
  color: color("warning", "darkest");
  &:after {
    background: color("warning");
  }
}

.address {
  &,
  &.customAddress {
    &.noBackground {
      color: inherit;
      background-color: inherit;
      padding: 0;
      &:after {
        display: none;
      }
    }
  }
}
.noValue {
  color: color("text", "light");
  font-weight: $fontNormal;
  opacity: 0.8;
}
