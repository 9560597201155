@import "styles/common";

.root {
  width: 80%;
  position: relative;
  margin: 0 auto;
  @include desktop {
    width: 40%;
    margin: 0;
    //padding-bottom: 20px;
  }
  &.labels {
    margin-bottom: 18px;
  }
}

.dotWrap {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}

.dot {
  z-index: 1;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 3px solid color("grey");
  background-color: $background;
  transition: background-color 200ms ease-in, border 100ms ease-in-out;

  float: left;
  position: relative;

  span {
    top: 12px;
    clear: left;
    float: left;
    left: 50%;
    position: absolute;
    text-align: center;
  }

  label {
    display: block;
    float: left;
    margin: 0;
    padding: 0;
    position: relative;
    right: 50%;

    color: color(text, light);
    font-size: rem(16px);
  }

  &.prev {
    border: 2px solid color(primary);
    background-color: color(primary);
  }

  &.active {
    border: 3px solid color(primary);
    background-color: $background;
    transition: background-color 200ms ease-in, border 200ms ease-in-out 200ms;
    label {
      color: color(primary);
      font-weight: $fontBold;
    }
  }
}

.lineWrap {
  position: absolute;
  left: 0;
  right: 0;
}
.line {
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  right: 3px;
  top: 7px;
  border: 1px solid color(grey);
  background: color(grey);
  transition: width 200ms ease-in-out;
  //width: 100%;
  //margin: 5% auto;
  &.active {
    top: 6px;
    background: color(primary);
    border: 2px solid color(primary);
  }
}
