@import "styles/common";
.root {
  //width: 220px;
}
.houseBrand {
  fill: color(secondary, "light");
}
.lightBrand {
  fill: #fff;
}

.contrast {
  .houseBrand {
    fill: color("primary", "background");
  }
  .lightBrand {
    fill: #ffffff;
  }
}

.dark {
  .houseBrand {
    fill: color("primary", "background");
  }
  .lightBrand {
    fill: color("primary", "light");
  }
}
.default {
  .houseBrand {
    fill: #fff;
  }
  .lightBrand {
    fill: color("secondary", "light");
  }
}
