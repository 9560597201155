@use "styles/common" as theme;

.menu {
  @include theme.flex(center, space-between);
  .actionButton {
    width: 28px;
    height: 28px;
    svg {
      font-size: 18px;
    }
    &.notActive:not(:focus) {
      opacity: 0.4;
    }
  }
  &.focused .actions {
    background-color: rgba(theme.color("grey", "lightest"), 0.4);
    //background-color: rgba(#ccc, 0.4);
    border-color: theme.color(primary, lighter);
    .spacer {
      border-color: theme.color(primary, lighter);
    }
  }
}
.actions {
  border: 1px solid #eee;
  border-bottom: 0;
  background-color: rgba(theme.color("grey", "lightest"), 0.6);
  border-radius: 8px 8px 0 0;
  @include theme.flex();
  width: fit-content;
  padding: 0 4px;
  font-size: 0.8em;
  transition: border-color 0.7s, background-color 0.7s;
}

.spacer {
  border-left: 1px solid rgba(theme.color("grey", lighter), 0.6);
  margin: 0 4px;
  transition: border-left-color 0.7s;
}

/* Basic editor styles */

//.ProseMirror {
//  > * + * {
//    margin-top: 0.75em;
//  }
//
//  ul,
//  ol {
//    padding: 0 1rem;
//  }
//
//  h1,
//  h2,
//  h3,
//  h4,
//  h5,
//  h6 {
//    line-height: 1.1;
//  }
//
//  code {
//    background-color: rgba(#616161, 0.1);
//    color: #616161;
//  }
//
//  pre {
//    background: #0D0D0D;
//    color: #FFF;
//    font-family: 'JetBrainsMono', monospace;
//    padding: 0.75rem 1rem;
//    border-radius: 0.5rem;
//
//    code {
//      color: inherit;
//      padding: 0;
//      background: none;
//      font-size: 0.8rem;
//    }
//  }
//
//  img {
//    max-width: 100%;
//    height: auto;
//  }
//
//  blockquote {
//    padding-left: 1rem;
//    border-left: 2px solid rgba(#0D0D0D, 0.1);
//  }
//
//  hr {
//    border: none;
//    border-top: 2px solid rgba(#0D0D0D, 0.1);
//    margin: 2rem 0;
//  }
//}
