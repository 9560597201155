@import "input";

// extending standard input styles
.input {
  &,
  &[type="email"],
  &[type="number"],
  &[type="search"],
  &[type="text"],
  &[type="tel"],
  &[type="url"],
  &[type="password"],
  &[type="date"],
  &.area {
    &.alternative:focus {
      border-color: transparentize(color(secondary), 0.5);
    }
  }
}
.root {
  &.iconInput {
    position: relative;
    .icon {
      pointer-events: none;
      left: 16px;
      top: 22px;
      height: 42px;
      position: absolute;
      display: flex;
      align-items: center;
      width: 24px;
      color: color(grey);
      transition: color 0.7s;
    }
    .input,
    input {
      padding-left: 48px;
      &:focus ~ .icon {
        color: color(primary);
      }
    }
    &.error {
      .icon {
        color: color(danger, dark);
      }
    }
    &.disabled {
      .icon {
        opacity: 0.5;
      }
    }
  }
  &.rounded {
    .input,
    input {
      border-radius: 30px;
    }
    .label {
      padding-left: 12px;
    }
  }
  &.condensed {
    &,
    &.skeleton,
    &.iconInput {
      .input,
      input {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .icon {
        height: 38px;
      }
    }
    &.skeleton {
      .input,
      input {
        height: 38px;
      }
    }
  }
}

.hint {
  font-size: 0.8em;
  padding-left: 10pt;
  margin-bottom: 1.4em;
  letter-spacing: 0.03em;
  text-align: left;
  overflow-y: hidden;
  display: flex;
  align-items: center;
}

.passwordWrapper {
  position: relative;
  .input,
  input {
    padding-right: 64px;
  }
  .toggle {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 12px;
    height: 24px;
    width: 24px;
    padding: 0;
    color: color(text);
    svg {
      max-width: 100%;
    }
    &:hover,
    &:focus {
      color: color(secondary, dark);
    }
    &.toggleLabel {
      top: 32px;
    }
  }
}
.area {
  transition: background-color 0.7s, border 0.7s !important;
}

.skeleton {
  .label {
    position: relative;
    width: 40%;
  }
  .input,
  input {
    height: 42px;
    position: relative;
    padding: 0;
  }
}
