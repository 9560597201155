@import "styles/common";

.root {
  margin-bottom: 24px;

  .header {
    //padding: 0;
    padding-bottom: 0;
    width: 100%;
    h1 {
      color: color(secondary);
    }
  }
}

.placeholder {
  font-style: italic;
}
