@import "styles/common";

.root {
  @include flex--row(center);
  position: relative;
  width: 100%;
  //padding: 16px;
  border: 1px solid color(grey, lighter);
  border-radius: 6px;
  overflow: hidden;
  padding-right: 16px;
  &.loading {
    .icon {
      & > svg {
        opacity: 0.5;
      }
    }
  }
  &.success {
    .icon {
      border: 2px solid color(success);
      color: color(success);
      background-color: color(success, lightest);
    }
  }
  &.half {
    @include desktop {
      min-width: 49%;
    }
  }
  .progress {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    //width: 100%;
  }
}

.avatar {
  @include flex(center, center);
  margin: 0 16px 0 28px;
  .icon {
    position: relative;
    @include flex(center, center);
    background-color: color(grey, lightest);
    color: color(grey);
    border-radius: 8px;
    width: 40px;
    height: 40px;
    transition: background-color, border 200ms ease-in-out;
    .loader {
      //transform: scale(0.9);
      position: absolute;
    }
  }
}

.content {
  flex-grow: 1;
  padding: 16px 0;
  .path,
  .size {
    margin: 0;
  }
  .size {
    font-size: 0.9em;
    color: color(text, light);
  }
}
