@import "styles/common";

.content {
  position: relative;
  min-width: 80%;
  max-width: 780px;

  min-height: 400px;
  pointer-events: auto;
  background: #fff;
  padding: 20px;
  overflow: auto;
  border-radius: 6px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
  //box-shadow: $map-component-shadow;
  cursor: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 20px);
  margin-top: 20px;
  @include desktop {
    min-width: min(max(50%, 900px), 80%);
  }
  @include subtle_scrollbar;
  .close {
    position: absolute;
    z-index: 999;
    right: 12px;
    top: 12px;
    padding: 2px;
    border-radius: 50%;
    margin: 0;
    .closeIcon {
      font-size: 25px;
      color: color("grey");
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #04040f;
  pointer-events: none;
  z-index: -1;
  //opacity: 0;
}
.baseContent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.baseOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
