@import "styles/common";

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 4px;
  border-color: #eeeeee;
  //border-color: color(grey, 300);
  border-style: dashed;
  background-color: #fafafa;
  //background-color:color(grey, 100);
  color: color(grey, dark);
  outline: none;
  transition: border, background-color 0.24s ease-in-out;
  cursor: pointer;
  text-align: center;
  &:hover,
  &:focus {
    background-color: color(grey, lightest);
    border-style: solid;
    border-color: color(grey, lighter);
    .uploadIcon {
      background-color: color(grey, lighter);
    }
  }
  p span {
    color: color(text, secondary);
  }
}

.input {
  display: none;
}
.uploadIcon {
  pointer-events: none;
  margin: 16px;
  @include flex(center, center);
  //background-color: var(--nextui-colors-accents2);
  background-color: color(grey, lightest);
  transition: border, background-color 0.24s ease-in-out;
  //color: #435a6f;
  color: color(grey, darker);
  border-radius: 30px;
  width: 60px;
  height: 60px;
  svg {
    font-size: 34px;
  }
}

.fileList {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px 16px 16px 12px;
}
