@import "styles/common";

.container {
  width: 100%;
  //width: $containerWidth;
  max-width: $containerMax;
  //margin: 0 auto;
  position: relative;
  background: color(grey, lightest);
  border-radius: 4px;
  padding: 8px 16px 8px 20px;
  //min-height: 100vh;

  @include psuedo--before {
    left: 0;
    bottom: 0;
    top: 0;
    width: 8px;
    background: color(grey, darker);
    border-radius: 4px 0 0 4px;
  }
  &.spacing-none {
    padding: 0 0 0 16px;
  }
  @include desktop {
    &.spacing {
      &-small {
        padding: 8px 16px 8px 20px;
      }
      &-normal {
        padding: 16px 24px 16px 28px;
      }
      &-large {
        padding: 24px 32px 24px 36px;
      }
      &-extra {
        padding: 32px 42px 32px 48px;
      }
    }
  }
  &.iconContainer {
    @include flex--row();
    &.spacing-none {
      padding: 0 0 0 8px;
    }
    @include desktop {
      &.spacing {
        &-small {
          padding: 8px 16px 8px 12px;
        }
        &-normal {
          padding: 16px 24px 16px 20px;
        }
        &-large {
          padding: 24px 32px 24px 28px;
        }
        &-extra {
          padding: 32px 42px 32px 40px;
        }
      }
    }
    .icon {
      flex-shrink: 0;
      margin: 2px 12px 0 0;
    }
  }
}

@mixin hintVariant($key) {
  color: color($key, darkest);
  background: color($key, lightest);
  &::before {
    background: color($key, dark);
  }
  .icon {
    color: color($key, dark);
  }
}

.primary {
  @include hintVariant("primary");
}
.secondary {
  @include hintVariant("secondary");
}
.success {
  @include hintVariant("success");
}
.info {
  @include hintVariant("info");
}
.warning {
  @include hintVariant("warning");
}
.danger {
  @include hintVariant("danger");
}
.grey {
  @include hintVariant("grey");
}
