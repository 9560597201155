@import "styles/common";

$barHeight: 18px;

.req {
  @include flex--row(flex-start, flex-start, wrap);
  .row {
    width: 49%;
    height: 24px;
    margin-right: 8px;
    @include flex--row(center);
    .icon {
      margin-right: 4px;
      height: 24px;
    }
    .chartWrap {
      background-color: color(grey, lighter);
      border-radius: 16px;
      height: $barHeight;
      flex-grow: 1;
      position: relative;
      overflow: hidden;
      .chartBar {
        position: absolute;
        height: $barHeight;
        left: 0;
        //border-radius: 16px;
        background-color: color(success);
        //background-color: color(primary);
        &.planned {
          opacity: 0.8;
          background-color: color(warning, lighter);
        }
        &.waived {
          background-color: color(info);
          @include stripes(#fff, 10px);
        }
      }
      .chartLabel {
        z-index: 2;
        position: absolute;
        right: 8px;
        top: -3px;
        margin: auto 0;
        height: $barHeight;
        font-weight: $fontMedium;
        color: color(text, dark);
        span {
          font-weight: $fontNormal;
          color: color(text, light);
        }
      }
    }
    &.complete {
      color: color(success, dark);
      .chartWrap {
        .chartBar {
          background-color: color(success);
          &.waived {
            background-color: color(success, dark);
          }
        }
      }
    }
  }
  .single {
    margin-right: 4px;
    &.waived {
      @include stripes(color(info, dark), 5px);
    }
  }
}
