@import "styles/common";

$themeVariants: (primary, secondary, success, info, warning, danger, grey);

.toggle {
  @include flex--row(stretch, stretch);

  border-radius: 20px;
  height: 38px;
  width: fit-content;

  button {
    cursor: pointer;
    padding: 0 12px;
    color: color(grey, light);
    background-color: rgba(242, 242, 240, 0.6);
    border: 1px solid color(grey, lighter);
    opacity: 0.8;
    transition: opacity, background-color, color 200ms ease-in-out;
    &:hover {
      opacity: 1;
    }

    &:nth-child(1) {
      border-radius: 20px 0 0 20px;
      padding-left: 16px;
      border-right: none;
      svg {
        margin-top: 2px;
      }
      &:hover {
        color: color(danger, darker);
        background-color: color(danger, lightest);
      }
    }
    &:nth-child(2) {
      border-radius: 0 20px 20px 0;
      padding-right: 16px;
      &:hover {
        color: color(primary, darker);
        background-color: color(primary, lightest);
      }
    }
  }

  &.activeToggle {
    button {
      &:nth-child(1) {
        //color: color(grey, light);
        //background-color: rgba(242, 242, 240, 0.6);
      }
      &:nth-child(2) {
        color: color(primary);
        background-color: color(primary, lightest);
      }
    }
  }
  &:not(.activeToggle) {
    button {
      &:nth-child(1) {
        color: color(danger, dark);
        background-color: color(danger, lightest);
      }
      &:nth-child(2) {
        //color: color(primary);
        //background-color: color(primary, lightest);
      }
    }
  }
  @for $i from 1 through length($themeVariants) {
    $c: nth($themeVariants, $i);
    &.#{$c} {
      button {
        &:hover {
          color: color($c, darker);
          background-color: color($c, lighter);
        }
      }

      &.activeToggle {
        button {
          &:nth-child(2) {
            color: color($c);
            background-color: color($c, lightest);
          }
        }
      }
      &:not(.activeToggle) {
        button {
          &:nth-child(1) {
            color: color($c, dark);
            background-color: color($c, lightest);
          }
        }
      }
    }
  }
}
