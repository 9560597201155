@import "styles/common";

div.root {
  max-width: 500px;
  min-width: 400px;
  min-height: 260px;
  padding: 24px 32px;
}
.heading {
  font-size: rem(18px);
  margin: 12px 0;
  font-weight: $fontBold;
  color: color(text, dark);
}
