@use "styles/common" as theme;

.avatar {
  background-color: theme.color(primary);
  border: 2px solid theme.color(grey, lightest);
  border-radius: 50%;
  color: theme.color(text, alt);
  font-weight: theme.$fontMedium;

  //display: inline-block;
  @include theme.flex(center, center);
  transition: transform 200ms ease-in-out;
  &:hover:not(.overflow) {
    transform: scale(1.1);
  }
  &.overflow {
    opacity: 0.9;
    color: theme.color(text, dark);
    border-color: theme.color(grey, lightest);
    background-color: theme.color(grey, light);
  }
}
.size {
  &-xs {
    width: 22px;
    height: 22px;
    font-size: 8px;
  }

  &-sm {
    width: 28px;
    height: 28px;
    font-size: 11px;
  }
  &-md {
    width: 36px;
    height: 36px;
    font-size: 14px;
  }
  &-lg {
    width: 42px;
    height: 42px;
    font-size: 18px;
  }
  &-xl {
    width: 52px;
    height: 52px;
    font-size: 22px;
  }
}

.group {
  padding: 4px;
  width: fit-content;
  height: auto;
  //margin-left: 8px;
  @include theme.flex(center, center);

  & > .avatar {
    &:first-child {
      margin-left: 0 !important;
    }

    &.size-sm {
      margin-left: -6px;
    }
    &.size-md {
      margin-left: -8px;
    }
    &.size-lg {
      margin-left: -12px;
    }
  }
}
