@import "styles/common";

.deletedUser {
  background-color: color(danger, lightest);
  border-radius: 4px;

  h4 {
    padding: 16px 0;
    color: color(text, danger);
    margin: 0;
    text-align: center;
    font-weight: $fontMedium;
    @include flex(center, center);
    svg {
      margin-right: 6px;
    }
  }
}
.icon {
  position: relative;
  display: inline-block;
  height: 16px;
  width: 32px;
  > svg {
    position: absolute;
    left: 0;
    top: 0;
  }
}
.content {
  background-color: #fff;
  min-height: calc(100vh - 300px);
  height: fit-content;
}

.sectionTitle {
  color: color(primary);
  border-bottom: 1px solid color(grey, lighter);
  font-weight: $fontBold;
}

.subSectionTitle {
  color: color(primary, light);
  font-size: rem(18px);
  font-weight: $fontBold;
}
.card {
  & > div {
    max-width: 450px;
  }
  .cardTitle {
    margin-top: rem(8px);
    font-size: 24px;
    line-height: 1.4;
  }
}

.tabDetailTitle {
  color: color(primary);
  font-weight: $fontMedium;
  font-size: 22px;
  padding: 0;
  border-bottom: 1px solid color(grey, lighter);
}

.buttonPlace {
  float: right;
  margin-top: -5px;
  border-radius: 50px;
}

.tab {
  display: flex;
  justify-content: center;
}
.tabIcon {
  margin-top: -5px;
  margin-right: 5px;
  //background-color: color(danger, lightest);
}

.buttonMember {
  float: left;
  max-width: 250px;
}

.itemsHeader {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  //flex-direction: row;
  //  flex-wrap: wrap;
}

.item {
  margin-right: 10px;
  margin-top: 4px;
  font: 600 1.4rem "Montserrat", sans-serif;
  font-size: 16px;
}

.groupHeader {
  @include flex--row(flex-end, space-between);
}
.activityList {
  list-style-type: none;
  padding-inline-start: 0;
  @include desktop {
    padding-inline-start: inherit;
  }
}
.row {
  @include flex--row(flex-end, flex-end, wrap);
  @include desktop {
    @include flex--row(center, flex-start);
    & > div {
      margin-left: 8px;
    }
  }
  h3 {
    flex-shrink: 0;
  }
  h2 {
    margin-top: 12px;
  }
}
.gantt {
  margin-top: 24px;
  overflow-x: auto !important;
  @include subtle_horizontal_scrollbar;
  & > div {
    min-width: 900px;
  }
}

.counter {
  padding: 0;
  margin-left: 4px;
  @include flex--row(center, space-between);
  .label {
    color: color(primary, light);
    font-weight: $fontBold;
    padding: 6px 8px 6px 16px;
    font-size: 0.9em;
    //margin-right: 12px;
  }
  .count {
    color: color(primary);
    background-color: #fff;
    font-weight: $fontBold;
    height: 100%;
    padding: 6px 16px 6px 12px;
    border: 2px solid color(primary, lightest);
    border-radius: 0 22px 22px 0;
  }
}
.reqLabel {
  font-weight: $fontBold;
  color: color(text, dark);
  font-size: rem(16px);
  margin: 24px 0 12px 0;
  span {
    padding-left: 4px;
    font-weight: $fontNormal;
    font-style: italic;
  }
}

.waived {
  @include stripes();
}

.bgGrey {
  margin-top: 16px;
  background-color: rgba(color(grey, lightest), 0.5);
  border-top: 1px solid color("grey", "lighter");
}

.tabLock {
  font-size: rem(14px);
  display: inline-block;
  vertical-align: bottom;
  //margin-top: 4px;
  margin: 0 4px 2px 0;
  opacity: 0.6;
  //align-self: flex-end;
}
.spacer {
  flex-grow: 1;
}
.hr {
  margin: 32px 0 12px 0;
  //border-color: color("grey", "lighter");
  border-top: 1px solid color("grey", "lighter");
}
