@import "styles/common";

.root {
}
.label {
  //color: color(text, light);
  font-size: rem(14px);
  font-weight: $fontBold;
  //margin-bottom: 4px;
}
.items {
  //margin: 4px;
  margin-bottom: 8px;
  border: 1px solid color(grey, lightest);
  padding: 8px 16px;
  border-radius: 6px;
  background: rgba(color("grey", "lightest"), 0.2);
  .input input {
    background-color: color("grey", "lightest");
  }
  @include desktop {
    @include flex--row(flex-start, space-between, wrap);
    .input {
      width: calc(50% - 4px);
      &:nth-child(1) {
        width: 100%;
      }
    }
  }
}
