@import "styles/common";
.group {
  display: flex;
  text-align: left;
  border: none;
  padding: 0;
  flex-direction: column;
  div {
    margin-left: 16px;
    width: 100%;
  }
}

.legend {
  color: color("grey", "dark");
  font-size: 0.9em;
  font-weight: $fontBold;
  padding-bottom: 4px;
  span {
    color: color(text);
    font-weight: normal;
    font-style: italic;
  }
}

.error {
  .legend {
    color: color("text", "danger");
    font-weight: bold;
  }
}
