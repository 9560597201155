@import "styles/common";
@import "../form-elements/common/input";

.root {
  position: relative;
  width: 100%;
  &.disabled {
    .inputWrapper {
      cursor: not-allowed;
      color: color("text", light);
      background: none;
      border: 1px solid #eee;
      //background-color: transparentize(color("grey", "lighter"), 0.3);
      &,
      & > button {
        cursor: not-allowed;
      }
      .placeholder {
        color: color(grey, lighter);
      }
    }
    &.selectedChip {
      &.chip {
        .inputWrapper {
          padding-right: 2px;
        }
      }
    }
  }
  //override standard input styles
  .input {
    padding: 1px 2px;
    border: 0;
    background-color: transparent !important;
  }
}
.inputWrapper {
  position: relative;
  width: 100%;
  cursor: pointer;
  border: 1px solid rgba(color("grey", "lightest"), 0.8);
  transition: 0.7s;
  padding: 12px 18px;
  border-radius: 8px;
  background-color: rgba(color("grey", "lightest"), 0.6);
  &.focus {
    border-color: transparentize(color(primary), 0.5);
    outline: 0;
    background-color: $background;
  }
  &.alternative.focus {
    border-color: transparentize(color(secondary), 0.5);
  }
  & > button {
    cursor: pointer;
  }

  .input {
    cursor: pointer;
    padding-right: 50px;
  }
  .expand {
    position: absolute;
    right: 16px;
    top: 8px;
    color: color("grey", "light");
    font-size: rem(28px);
    pointer-events: none;
    @media print {
      display: none;
    }
  }
}
.chip {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  width: fit-content;
  transition: max-width 0.2ms ease-in-out;
  max-width: 100vw;
  overflow: hidden;
  label {
    pointer-events: none;
    padding: 6px 6px 4px 12px;
    font-size: rem(11px);
    font-weight: $fontBold;
    color: color("grey");
    margin-left: -10px;
    width: fit-content;
  }
  .inputWrapper {
    border-radius: 20px;
    min-height: 38px;
    width: fit-content;
    padding: 2px 2px 2px 12px;
    display: flex;
    .expand {
      top: 4px;
    }
    .input {
      flex-grow: 1;
      width: auto;
    }
  }

  .placeholder {
    padding: 4px 10px 4px 2px;
    color: lighten(color(text), 30%);
    font-size: 0.9em;
    line-height: 1.9;
  }
}

.selectedChip {
  .inputWrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 6px 50px 6px 18px;
    min-height: 48px;
    max-width: 100%;
    .input {
      color: transparent;
      padding-right: 0;
      width: 2px;
    }
    .inputCapture {
      @include absolute-cover();
    }
    .activeChip {
      z-index: 1;
      position: relative;
    }
  }
  &.chip {
    .inputWrapper {
      padding: 2px 32px 2px 12px;
      min-height: 38px;
      min-width: 120px;
      .expand {
        right: 6px;
      }
      .input {
        height: 0;
        overflow: hidden;
        //position: absolute;
        //visibility: hidden;
      }
      @media print {
        padding-right: 2px;
      }
    }
  }
}
.hideExpandArrow {
  &.chip {
    .inputWrapper {
      padding: 2px 2px 2px 16px;
    }
  }
  .expand {
    display: none;
  }
}

.activeChipContainer {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  &.singleSelect {
    pointer-events: none;
  }
}

.skeleton {
  min-height: 36px;
  .padder {
    width: 110px;
  }
  .expand {
    position: absolute;
    right: 16px;
    //top: 8px;
    color: color("grey", "light");
    font-size: rem(28px);
    pointer-events: none;
  }
}
