@import "styles/common";

.root {
  padding: 0 12px;
}

.root {
  .title {
    color: color(primary);
    border-bottom: 1px solid color(grey, lighter);
    font-weight: $fontBold;
  }
}
