@import "../common/input";

.root {
  padding: 0 1px 0 1px;
  margin-bottom: 16px;
}

.item {
  width: 100%;
  border: 1px solid color(grey, lightest);
  border-radius: 6px;
  padding: 4px 8px;
  margin-bottom: 4px;
  @include flex--column;
  @include desktop {
    //@include flex--row(flex-start, stretch);
    & > div {
      margin-right: 8px;
      flex-grow: 1;
    }
  }
  .question {
    label {
      font-style: italic;
    }
    &.boolean {
      flex-grow: 0;
      padding-right: 8px;
    }
    &[role="combobox"] {
      padding-top: 22px;
    }
  }
  .questionWrap {
    //flex: 0 1 auto;
    flex: 1;
  }
}
.delete {
  margin-top: 22px;
  //align-self: center;
}
