@import "styles/common";

.root {
  h4 {
    margin-top: 0;
    font-weight: $fontMedium;
  }
}

.spacer {
  opacity: 0.5;
}
