@import "styles/common";

.root {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: $mediumShadow;
  @include flex--row(stretch, space-between);
  margin-bottom: 8px;
  color: color(text);
  &.editable {
    transition: 400ms ease-in-out;
    transition-property: background-color, box-shadow;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
      //transform: scale(1.0001);
      box-shadow: $heavyShadow;
    }
  }
}

.rightColumn {
  @include flex--column(flex-end, space-between);
  & > div {
    margin-bottom: 12px;
  }
}

.heading {
  margin-top: 0;
  color: color(text, primary);
  @include flex--row(center);
  font-weight: $fontMedium;
  svg {
    margin-right: 8px;
  }
}
