@import "styles/common";
.root {
  padding: 0 18px 0 16px;
  h5 {
    font-weight: $fontMedium;
    color: color(text, primary);
  }
}
.header {
  width: 100%;
  @include flex--row(center, space-between);
  padding-right: 32px;
}
