@import "styles/common";

.noValue {
  color: color("text", "light");
  font-weight: $fontNormal;
  opacity: 0.8;
}

.fullWidth {
  width: 100%;
}

.success {
  margin-top: 4px;
  color: color("success", "dark");
}
.danger {
  margin-top: 4px;
  color: color("danger", "dark");
}

.row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //margin-bottom: 8px;
  flex-wrap: wrap;
  padding: 8px 12px;
  border-radius: 4px;

  &:hover {
    background-color: color("grey", "lightest");
  }
  //min-height: 40px;
  .label {
    box-sizing: border-box;
    color: color("text", "light");
    font-weight: $fontBold;
    //flex-grow: 0.25;
    min-width: 250px;
    //max-width: 200px;
    margin-right: 6px;
  }
  .value {
    flex-grow: 1;
    color: color("text", "dark");
    font-size: 1.05em;
    display: flex;
    a {
      color: color("text", "dark");
      @include desktop {
        &:hover,
        &:focus {
          text-decoration: underline;
          color: color("secondary", "dark");
        }
      }
    }
  }
  &.spacing-none {
    .label {
      min-width: 0;
    }
  }
}
.chipContainer {
  @include flex--row(flex-start, flex-start, wrap);
}
.chip {
  margin-right: 4px;
  margin-bottom: 4px;
  width: fit-content;
}
.heading {
  font-weight: bold;
  color: color(primary, light);
  margin-top: 6px;
  border-bottom: 1px solid color(grey, lighter);
  width: 100%;
}
.fieldRow {
  width: 100%;
  flex-grow: 1;
}
.fieldArrayTable {
  width: 100%;
  margin-bottom: 8px;
}
