@import "styles/common";

.pageHeader {
  padding: 60px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include desktop {
    padding-top: 40px;
    align-items: start;
    flex-direction: row;
  }
}

.content {
  flex-grow: 1;
  text-align: center;
  padding: 12px 0;
  @include desktop {
    padding: 0;
    text-align: left;
  }
  h1 {
    //font-weight: $fontNormal;
    margin-bottom: rem(8px);
  }

  .item {
    @include flex(flex-start, flex-start, row, wrap);
    padding-bottom: rem(8px);
    width: fit-content;
    margin: 0 auto;
    h2 {
      margin: rem(-6px) 0 0 0;
    }
    @include desktop {
      margin: 0;
    }
    &,
    .text a {
      color: rgba(color("text"), 0.7);
      font-size: rem(16px);
    }
    .icon {
      color: color("primary", "light");
      margin: rem(4px) rem(12px) rem(4px) rem(6px);
      font-size: rem(20px);
    }
    > div {
      margin-right: 6px;
    }
    .text {
      flex-grow: 1;
      margin-right: 12px;
      a {
        @include desktop {
          &:hover,
          &:focus {
            text-decoration: underline;
            color: color("secondary", "dark");
          }
        }
      }
      span {
        font-weight: $fontMedium;
        color: color(text, dark);
      }
    }
  }
}

.actionContainer {
  @include flex--column();
  .actions {
    @include flex--row(center, center, wrap);
    @include desktop {
      @include flex(center, flex-end);
    }
  }
}
