@import "styles/common";

.root {
  //background-color: #edeff3;
  background-color: #f0f1f3;
}

.pageTitle {
  color: color(primary, darker);
}

.pageBlurb {
  //margin-bottom: 32px;
}
.clientTitle {
  color: color(text, light);
  margin: 0 0 -6px 0;
}
.clientLogo {
  //width: 350px;
  max-height: 140px;
  margin-bottom: 12px;
  max-width: 70%;
  @include desktop {
  }
}
//.ribbon {
//  position: absolute;
//  z-index: -1;
//  top: 100px;
//  right: -50px;
//  transform: rotateX(180deg) rotateY(180deg) rotate(45deg);
//  width: 600px;
//  @include desktop{
//    top: 10px;
//    right: -20px;
//    width: 420px;
//    transform: rotateX(180deg) rotateY(180deg) rotate(20deg);
//  }
//  @include huge{
//    right: -200px;
//    top: 100px;
//    width: 600px;
//    transform: rotateX(180deg) rotateY(180deg) rotate(45deg);
//  }
//  @media (min-width: 1800px) {
//    right: -50px;
//  }
//}
.readMore {
  color: color(primary);
  font-weight: $fontMedium;
  padding: 0;
  border-bottom: 1px solid color(grey, lighter);
}

.textBox {
  background-color: #f1f4fc;
  box-shadow: 5px 5px 10px #dddee0, -5px -5px 10px #ffffff;
  border-radius: 18px;
  padding: 18px 22px;
  text-align: justify;
  max-width: 98.4%;
  margin-left: 10px;
  //margin-bottom: 10px;
}

.textTitle {
  color: #4527a0;
  font-weight: 700;
  font-size: 20px;
}
