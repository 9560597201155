@import "styles/common";

.progressWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
}
.progress {
  background: color("text");
  height: 3px;
  width: 100%;
  border-radius: 2px;
  opacity: 0.6;
}

@mixin format-progress($color) {
  &.progressWrapper {
    .progress {
      background: $color;
    }
  }
}

.default {
  @include format-progress(color("text"));
}
.primary {
  @include format-progress(color("text", "primary"));
}
.secondary {
  @include format-progress(color("text", "secondary"));
}
.success {
  @include format-progress(color("success", "dark"));
}
.info {
  @include format-progress(color("info", "darker"));
}
.warning {
  @include format-progress(color("warning", "darker"));
}
.danger {
  @include format-progress(color("danger", "darker"));
}
