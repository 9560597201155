@import "styles/common";

.item {
  padding-left: 12px;
  @include flex--row(flex-start);
  margin-bottom: 10px;
  svg {
    font-size: 16px;
    color: color(grey, light);
    margin-right: 6px;
  }
  .text {
    white-space: pre-line;
    text-align: left;
    line-height: normal;
  }
  &.complete {
    .text {
      color: color(text, light);
      text-decoration: line-through;
    }
  }
}
