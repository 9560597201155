@import "styles/common";

.input {
  position: absolute;
  left: -9999px;
  clip-path: polygon(0 0);
}

.checkbox {
  position: relative;
  padding-left: 2em;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  user-select: none;
  color: color(primary);

  .label {
    color: color(text);
    font-weight: $fontBold;
    font-size: 0.9em;
  }

  &.disabled {
    cursor: not-allowed;

    &:not(.checked):before {
      border: none;
      background-color: color("grey");
    }

    &.checked:before {
      border-right: 2px solid color("grey");
      border-bottom: 2px solid color("grey");
    }
    &:hover {
      &,
      .input:focus ~ {
        .label {
          color: color("text", "light");
        }
      }
      .input:not(:checked) ~,
      .input:not(:checked):focus {
        .box:before {
          box-shadow: none;
          transform: scale(1);
        }
      }
    }
    .label {
      color: color("text", "light");
    }
  }
}
.box {
  position: absolute;
  top: 0;
  left: 0;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 3px;
    width: 15px;
    height: 15px;
    z-index: 0;
    background: color("grey", "lightest");
    border: 1px solid color("grey", "light");
    border-radius: 1px;
    margin-top: 4px;
    transition: 0.2s;
  }
}

.checkbox {
  .input:checked {
    ~ {
      .box:before {
        color: color("success");
        top: -2px;
        left: -2px;
        width: 10px;
        height: 18px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid currentColor;
        border-bottom: 2px solid currentColor;
        transform: rotate(40deg);
        backface-visibility: hidden;
        transform-origin: 100% 100%;
        background: transparent;
      }
      .label {
        color: color("text", "success");
      }
    }
  }
  &:hover,
  .input:focus ~ {
    .label {
      color: color("text", "secondary");
    }
  }
  &:hover .input:not(:checked) ~,
  .input:not(:checked):focus {
    .box:before {
      box-shadow: 0 rem(1px) rem(2px) rgba(0, 0, 0, 0.15);
      transform: scale(1.1);
    }
  }
  &:hover .input:checked ~,
  .input:checked:focus ~ {
    .box:before {
      border-top: 2.5px solid transparent;
      border-left: 2.5px solid transparent;
      border-right: 2.5px solid currentColor;
      border-bottom: 2.5px solid currentColor;
    }
  }
}
.checkbox.error {
  .input:not(:checked) ~ .box:before {
    border-color: color(danger);
    background-color: lighten(color("danger", "lightest"), 10%);
  }
}
