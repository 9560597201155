@import "../common/input";

.menu {
  position: relative;
  //width: 100%;
  min-width: fit-content;
  z-index: 999999999;
  border: 1px solid color("primary", "lighter"); //rgb(215, 217, 226);
  border-radius: rem(8px);
  font-size: rem(14px);
  background: #fff;
  box-shadow: rgb(215, 217, 226) 0 2px 4px 0;
  max-height: 400px;
  //transition: height 200ms ease-in-out;
  //transition: opacity 0.1s linear 0s, visibility 0.1s linear 0s,
  //  max-height 0.2s linear 0s;
  //transition: opacity 0.2s ease-in-out 0s;
  //max-height: 0;
  //opacity: 0;
  //visibility: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  @include subtle-scrollbar;
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar {
    background: none;
  }
  margin: 0;
  padding: 0;
  &.open {
    //visibility: visible;
    //opacity: 1;
    //max-height: fit-content;
    //margin-bottom: 16px;
  }
  li {
    //transition: margin 200ms ease-in-out;
    &:nth-child(1) {
      margin-top: 8px;
    }
    &:last-child {
      margin-bottom: 8px;
    }
  }
  .noMatch {
    padding: 8px 16px;
    font-style: italic;
    color: color(text, light);
  }
}
