@import "styles/common";
.dot {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 4em;
  height: 4em;
  font-size: 20px;
}
.dot div {
  position: absolute;
  top: 1.65em;
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.dot div:nth-child(1) {
  left: 0.4em;
  animation: dot1 0.6s infinite;
}
.dot div:nth-child(2) {
  left: 0.4em;
  animation: dot2 0.6s infinite;
}
.dot div:nth-child(3) {
  left: 1.6em;
  animation: dot2 0.6s infinite;
}
.dot div:nth-child(4) {
  left: 2.8em;
  animation: dot3 0.6s infinite;
}
@keyframes dot1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes dot3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes dot2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(1.2em, 0);
  }
}

.default {
  color: currentColor;
}

$themeVariants: (primary, secondary, success, info, warning, danger, grey);

.dot {
  @for $i from 1 through length($themeVariants) {
    $c: nth($themeVariants, $i);
    &.#{$c} {
      color: color($c);
    }
  }
}
