@import "styles/common";

.cell {
  align-self: flex-start;
  @include flex--row(flex-start, flex-start, wrap);
  & > a,
  & > div {
    margin-right: 4px;
    &:not(:first-child) {
      margin-bottom: 4px;
    }
  }
}

.rightAlign {
  @include right-align;
}

.outLinkIcon {
  opacity: 0.5;
  font-size: 16px;
  //margin-left: 6px;
  align-self: flex-start;
  margin: 5px 0 0 6px;
}
