@import "../common/input";

.root {
  margin-bottom: 16px;
}

.item {
  width: 100%;
  @include flex--column;
  @include desktop {
    @include flex--row(center, stretch);
    & > div {
      margin-right: 8px;
      flex-grow: 1;
    }
  }
  .question {
    label {
      font-style: italic;
    }
  }
}

.menu {
  position: relative;
  //width: 100%;
  z-index: 999999999;
  border: 1px solid color("primary", "lighter"); //rgb(215, 217, 226);
  border-radius: 0.875rem;
  font-size: rem(14px);
  background: #fff;
  box-shadow: rgb(215, 217, 226) 0 2px 4px 0;
  transition: opacity 0.1s linear 0s, visibility 0.1s linear 0s,
    max-height 0.2s linear 0s;
  max-height: 0;
  opacity: 0;
  visibility: hidden;

  overflow: hidden;
  margin-top: 12px;
  //@include desktop {
  //  position: absolute;
  //  top: 64px;
  //}
  //&.fixed {
  //  position: fixed;
  //  top: unset;
  //}
  &.visible {
    visibility: visible;
    opacity: 1;
    max-height: fit-content;
    //margin-bottom: 16px;
  }
  ul {
    padding: 16px 0;
    margin: 0;
  }
  li {
    cursor: pointer;
    list-style-type: none;
    padding: 6px 12px;
    transition: background-color 100ms ease-in-out;
    &.highlighted {
      background-color: color("grey", "lightest");
      &.active {
        background-color: transparentize(color("primary", "lighter"), 0.5);
      }
    }
    &.active {
      font-weight: $fontBold;
      color: color("text", "primary");
      background-color: transparentize(color("primary", "lightest"), 0.5);
    }
  }
}

.wrap {
  appearance: none;
  position: relative;
  @include flex--row {
    flex-wrap: wrap;
  }
  padding: 4px 42px 4px 12px;
  background-color: rgba(color("grey", "lightest"), 0.6);
  .bg {
    @include absolute-cover;
    pointer-events: none;
    border-radius: $radius;
    border: 1px solid #eee;
    display: block;
    margin: -1px;
    transition: 0.7s;
  }
  input,
  .searchIcon,
  .selected,
  .active {
    z-index: 2;
  }
  input {
    flex-grow: 1;
    min-width: 200px;
    position: relative;
    background-color: transparent;
    border: none;
    padding: 8px 0;
    padding-left: 6px;
    &:focus {
      outline: 0;
      & ~ .bg {
        z-index: 1;
        border: 1px solid transparentize(color(primary), 0.5);
        background-color: $background;
      }
      & ~ .searchIcon {
        color: color(primary);
      }
    }
    &:disabled {
      cursor: not-allowed;
      color: color("text", light);
      & ~ .bg {
        //background: none;
        background-color: transparent;
      }
    }
  }
  &.disabled {
    background: none;
  }
  .selected {
    border-radius: 20px;
    margin: 2px 2px 2px 0;
    button {
      margin: 0;
    }
  }
  .searchIcon {
    position: absolute;
    right: 0;
    top: 0;
    color: color(grey, light);
  }
}
